import React from 'react'
import PropTypes from 'prop-types'
import '../styles/main.scss'
import mail_icon from '../images/icons/mail.1.svg'
import phone_icon from '../images/icons/phone-yellow-2.svg'
import Img from 'gatsby-image'

const TeamMember = (props) => {
    const phoneNum = props.phone.replace(/\D/g,'');
    return (

    <div className='team-page__member member wow fadeInUp'>
        <div className="member__photo-container">

            {props.photo4.localFile.childImageSharp !== null &&
                <Img fluid={props.photo4.localFile.childImageSharp.fluid} className="member__img"/>
            }


        </div>
        <div className="member__text-block">
            <h3>
            {props.name}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: `<p>${props.title}</p>` + `<p><a href="mailto:${props.email}"><img class="member__icon" src=${mail_icon} alt="" /> ${props.email} </br></a>` + `<a href="tel:${phoneNum}"><img class="member__icon" src=${phone_icon} alt="" /> ${props.phone}</a></p>` + props.text }}/>
        </div>
    </div>
)
        }
TeamMember.propTypes = {
    email: PropTypes.string,
    title: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo4: PropTypes.node.isRequired
  }

export default TeamMember