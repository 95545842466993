import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import TeamMember from '../components/team-member'
import AnimateComponent from '../components/animate-component'
import { isSafari } from 'react-device-detect'
const TeamPage = (props) => (
  <Layout>
    <SEO title='Zespół | Świecki Kancelaria' />
    <AnimateComponent styleName='team-page' id='team-page' tag='section'>
      <div className='team-page__container'>

        <header className='team-page__header wow zoomIn'>
          <div className='team-page__title-container'>
            <h1>Działamy zespołowo</h1>
          </div>
        </header>
        {props.data.wordpressPage.acf.film_zespol?.localFile?.publicURL && props.data.wordpressPage.acf.pokaz_film_zespol &&
        <section className='movie wow fadeInUp'>
          <video loop={true} autoPlay={true} muted={true} playsInline={true}>
            <source src={props.data.wordpressPage.acf.film_zespol.localFile.publicURL} type='video/mp4' />
          </video>
        </section>
        }
        <div className='team-page__members'>
          {props.data.wordpressPage.acf.zespol.map((person, i) => (
            // console.log(person)
            <TeamMember
              key={i}
              name={person.imie_i_nazwisko}
              text={person.opis}
              email={person.email}
              title={person.tytul}
              photo4={person.zdjecie_4}
              phone={person.telefon}
            />
          ))}
        </div>
      </div>
    </AnimateComponent>
  </Layout>
)

export default TeamPage
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        wordpressPage( slug: {eq: "zespol"}) {
            id
            title
            content
            excerpt
            date
            modified
            slug
            status
            acf {
                pokaz_film_zespol
                film_zespol {
                    localFile {
                        publicURL
                    }
                }
                zespol {
                    imie_i_nazwisko
                    tytul
                    email
                    telefon
                    opis
                    zdjecie_4 {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 294, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        },
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    }`
